.footer {
    padding: 4rem 6rem;
    background: #000;
    color: #fff;
}

.fa-star {
    color: gold;
}

.top {
    display: flex;
    align-items: center;
    text-align: start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.top i {
    color: #fff;
    font-size: 2rem;
    margin-left: 1rem;
}

.top i:hover {
    color: #01959a;
}

.bottom {
    padding-top: 2rem;
    text-align: start;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.bottom div {
    display: flex;
    flex-direction: column;
}

.bottom h4 {
    font-size: 1.3rem;
    padding: 1rem 0 .8rem 0;
}

.bottom a {
    text-decoration: none;
    color: #fafafa;
    padding-bottom: .4rem;
    font-size: 1.1rem;
}

.bottom h6 {
    text-align: center;
    color: #01959a;
}

h4 {
    color: crimson;
}

h1 {
    color: #01959a
}

@media screen and (max-width:850px) {
    .footer {
        padding: 4rem 2rem;
    }
    .footer i {
        margin: 1rem 1rem 0 0;
    }
    .bottom div {
        width: 50%;
    }
    .bottom h6 {
        text-align: right;
        color: #01959a;
    }
}