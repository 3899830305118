            .acordion p {
                color: rgb(40, 40, 50);
                text-align: left;
            }
            
            @media screen and (max-width:850px) {
                .acordion p {
                    z-index: 99;
                }
                .acordion p {
                    color: rgb(40, 40, 50);
                    text-align: left;
                }
            }